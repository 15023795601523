import React from 'react'
import LoginView from './components/LoginView'
import './scss/index.scss'

const App = () => {
  return (
    <div>
      <LoginView />
    </div>
  )
}

export default App
