import React from 'react'
import './form-btn.scss'

const FormBtn = ({ onClick }) => {
  return (
		<button onClick={onClick} className='form-btn'>
			Отримати результати
		</button>
	)
}

export default FormBtn
